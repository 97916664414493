.point {
  position: absolute;
  // z-index: 1;
  #my-map {
    img {
      height: 30px;
      width: 30px;
    }
  }

  .point-info {
    width: 19%;
    transform: translate(-50%, -9%);
    margin: 0px auto;
    background-color: #ffff;
    border-radius: 5px;
    position: fixed;
    bottom: 7px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    // text-align: center;
    left: 50%;
    // border-width: 0px, 0px, 0px, 0px;
    // border-style: solid;
    // border-color: #91a4b75c;
    padding: 8px;

    ul {
      list-style: none;
      padding: 4px;
      margin-bottom: 0;
      li {
        .point-title {
          display: flex;
          justify-content: space-between;
          img {
            height: 17px;
            width: 17px;
            // padding: 2px;
          }
          span {
            text-transform: capitalize;
            font-size: 14px;
            font-weight: bolder;
            line-height: 20px;
            color: #0d1829;
          }
        }
        span {
          font-weight: 600;
          font-size: 12px;
          color: #61758a;
        }
        p {
          font-size: 12px;
          font-weight: 600;
          color: #1e74f3;
          line-height: 20px;
          margin-top: 7px;
          margin-bottom: 5px;
        }
      }
    }
    hr {
      margin: 0.5rem 0;
    }
    .point-direction {
      display: flex;
      .direction-btn,
      .share-btn {
        background-color: #e37547;
        border: none;
        border-radius: 8px;
        padding-block-end: 0;
        padding-block-start: 0;
        display: flex;
        margin: 0px 6px;
        justify-content: center;
        align-items: center;
        img {
          height: 20px;
          width: 20px;
        }
        span {
          font-size: 13px;
          font-weight: 600;
          margin-left: 6px;
          // padding: 3px;
          color: #ffff;
          word-spacing: 3%;
        }
      }
      .share-btn {
        background-color: #fee2d6;
        border: none;
        border-radius: 10px;
        padding: 8px;
      }
    }
    .add-content {
      padding-top: 7px;
      img {
        height: 25px;
        width: 25px;
      }
      span {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}
// .route-direction {
//   position: absolute;
//   top: 0;
//   left: 0;
// }
.pointinfo-select {
  width: 19%;
  transform: translate(11%, -9%);
  margin: 0px auto;
  background-color: #ffff;
  border-radius: 5px;
  position: fixed;
  bottom: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  // text-align: center;
  left: 50%;
  // border-width: 0px, 0px, 0px, 0px;
  // border-style: solid;
  // border-color: #91a4b75c;
  padding: 8px;

  ul {
    list-style: none;
    padding: 4px;
    margin-bottom: 0;
    li {
      .point-title {
        display: flex;
        justify-content: space-between;
        img {
          height: 17px;
          width: 17px;
          // padding: 2px;
        }
        span {
          font-size: 14px;
          font-weight: bolder;
          line-height: 20px;
          color: #0d1829;
        }
      }
      span {
        font-weight: 600;
        font-size: 12px;
        color: #61758a;
      }
      p {
        font-size: 12px;
        font-weight: 600;
        color: #1e74f3;
        line-height: 20px;
        margin-top: 7px;
        margin-bottom: 5px;
      }
    }
  }
  hr {
    margin: 0.5rem 0;
  }
  .point-direction {
    display: flex;
    .direction-btn,
    .share-btn {
      background-color: #e37547;
      border: none;
      border-radius: 8px;
      padding-block-end: 0;
      padding-block-start: 0;
      // padding: 8px;
      display: flex;
      margin: 0px 6px;
      justify-content: center;
      align-items: center;
      img {
        height: 20px;
        width: 20px;
      }
      span {
        font-size: 13px;
        font-weight: 600;
        margin-left: 6px;
        color: #ffff;
        word-spacing: 3%;
      }
    }
    .share-btn {
      background-color: #fee2d6;
      border: none;
      border-radius: 10px;
      padding: 8px;
    }
  }
  .add-content {
    padding-top: 7px;
    img {
      height: 25px;
      width: 25px;
    }
    span {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        form {
          .row {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .point {
    .point-info {
      width: 75%;
    }
  }
}

@media screen and (max-width: 700px) {
  .point {
    .point-info {
      width: 68%;
      bottom: 155px;
      left: 38%;
      // transform: translate(-50%, 157%);
    }
  }
}
