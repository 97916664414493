.top {
  // left: 137px;
  position: relative;
  z-index: 1;
  top: 6px;
  display: flex;
  padding: 0px 25px;
  // right: 35px;

  .react-multi-carousel-list {
    .react-multi-carousel-track {
      .react-multi-carousel-item {
        width: -webkit-fill-available !important;
      }
    }
  }

  .list {
    list-style: none;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 6px;
  }

  button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    // line-height: 20px;
    font-size: 18px;
    background-color: white;
    border: none;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    // height: 4vh;
    height: 29px;
    box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.2) 0px 1px 3px 1px;
    img {
      object-fit: contain;
      height: 15px;
      width: 15px;
      color: #812c19;
    }
  }
  button:active {
    background-color: rgb(227, 117, 71) !important;
  }
  button:hover {
    background-color: #f5f5f5;
  }
  // button:focus {
  //   background-color: rgb(227, 117, 71);
  // }
  span {
    color: #812c19;
    font-size: 13px;
    font-weight: bolder;
    padding: 4px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
}

.hidden {
  display: none;
}
.scroll-container {
  position: relative;
}

.top {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  white-space: nowrap;
}

.top::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.list {
  flex: 0 0 auto;
}

.scroll-arrow {
  // border: 1px solid #4e5a69;
  border-radius: 36%;
  position: absolute;
  top: 79%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #e9916c;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: hidden;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.2) 0px 1px 3px 1px;
}

.scroll-arrow.left {
  left: 0;
}

.scroll-arrow.right {
  right: 14px;
}

.scroll-container:hover .scroll-arrow {
  visibility: visible;
}
.scroll-arrow:hover {
  background-color: #e37547 !important;
}

.more-amenities {
  background-color: white;
  // border-radius: 0px 0px 30px 30px;
  width: 29.8vw;
  height: -webkit-fill-available;
  position: absolute;
  overflow-y: auto;
  left: 1.2%;
  top: 47px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  .more-amenity-header {
    display: flex;
    justify-content: space-between;
    padding: 17px;
    position: relative;
    top: 19px;
    span {
      font-size: 13px;
      font-weight: 600;
      color: rgb(74 86 102);
      // color: #91a4b7;
    }
    img {
      height: 25px;
      width: 25px;
    }
  }
  .list {
    list-style: none;
    padding: 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 8px;
    width: inherit;
  }
  .more-list {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bg {
    background-color: #e37547;
  }
  button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 20px;
    font-size: 18px;
    background-color: #ffff;
    border: none;
    border-radius: 6px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 6px;
    img {
      height: 32px;
      width: 32px;
      color: #812c19;
    }
  }
  button:hover {
    background-color: #c2c4c7a1;
  }
  span {
    color: #304254;
    font-size: 12px;
    font-weight: 600;
    padding: 3px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
}
.amenities-nodata {
  background-color: white;
  // border-radius: 0px 0px 30px 30px;
  width: 29.8vw;
  height: -webkit-fill-available;
  position: absolute;
  /* z-index: 4; */
  overflow-y: scroll;
  left: 1.2%;
  top: 47px;
  padding-top: 37px;
}
.no-scroll {
  overflow: hidden;
  overflow-y: hidden !important;
  .route-direction {
    position: fixed !important;
  }
}
.amenities {
  background-color: white;
  // border-radius: 0px 0px 30px 30px;
  width: 29.8vw;
  height: -webkit-fill-available;
  position: absolute;
  /* z-index: 4; */
  overflow-y: scroll;
  left: 1.2%;
  top: 47px;
  padding-top: 37px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  h4 {
    font-weight: 800;
    font-size: 14px;
    padding-left: 2rem;
  }

  .loading-screen {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #6dc8cf; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .loading-spinner {
    border: 7px solid #f3f3f3;
    border-top: 7px solid #e37547;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .amenity-header {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    span {
      font-size: 14px;
      font-weight: 600;
    }
    img {
      height: 25px;
      width: 25px;
    }
  }
  .content {
    list-style: none;
    padding-right: 2rem;
    // .amenity-content {
    // padding: 30px;
    li {
      cursor: pointer;
      span {
        font-size: 15px;
        font-weight: bolder;
        line-height: 27px;
        text-transform: capitalize;
      }
      // }
      p {
        color: #61758a;
        margin-bottom: 0;
        font-weight: 600;
      }
      hr {
        border: 0.2px solid rgba(145, 164, 183, 0.4);
        // color: rgba(145, 164, 183, 0.4);
      }
    }
  }
}

.amenity-points {
  .marker-img {
    height: 20px;
    width: 20px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  vertical-align: -0.2em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #91a4b7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #718eac;
}

::-webkit-progress-value {
  background-color: #812c19;
}
// @media (max-width: 1200px) {
//   .top {
//     // position: absolute;
//     top: 64px;
//     right: 0;
//     left: 0;
//     display: flex;
//   }
// }
