.route-direction {
  position: absolute;
  left: 17px;
  top: 47px;
  border-radius: 0px 0px 30px 30px;
  background-color: white;
  width: 29.8vw;
  height: 99vh;
  padding: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  .directions {
    position: relative;
    top: 11px;

    padding: 10px;
    h4 {
      margin: 14px;
      font-size: 0.8rem;
      font-weight: 700;
    }
    .icons {
      display: flex;
      justify-content: space-evenly;
      // padding-bottom: 23px;

      img {
        height: 35px;
        width: 35px;
        padding: 5px;
      }
      .route-duration {
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-weight: 600;
          font-size: 11px;
        }
      }

      .selected {
        background-color: #fee2d6;
        border-radius: 50%;
        // width: fit-content;
        position: relative;
      }
    }
    .overlay {
      position: relative;
      img {
        height: 27px;
        position: absolute;
        left: 3.4%;
        top: -19px;
      }
    }

    .direction-from,
    .direction-to,
    .locate {
      img {
        height: 20px;
        width: 20px;
      }
      input[type="text"]:focus {
        outline: 1px solid #e37547;
      }
      input[type="text"] {
        border: 1px solid #fee2d6;
        border-radius: 8px;
        width: 43vh;
        padding: 8px;
      }
      input[value] {
        font-weight: 800;
        font-size: 13px;
        color: #0d1829;
      }
      span {
        color: #0d1829;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        font-weight: 600;
        // line-height: 39px;
        letter-spacing: 0px;
        // text-align: left;
      }
    }
    // .locate:hover {
    //   background-color: #842f0b;
    // }
    .direction-from {
      // padding-bottom: 10px;
    }
    .reverse-places {
      // margin-top: 18px;
    }
    .locationsearch-data {
      ul {
        list-style: none;
        margin: 0;
        padding: 0px;
        // display: flex;
        // justify-content: space-between;
        li {
          font-size: small;
          padding-bottom: 54px;

          img {
            height: 20px;
            width: 20px;
          }
          span {
            font-family: "Montserrat", sans-serif;
            cursor: pointer;
            text-decoration: none;
            color: #000000;
            position: absolute;
            /* right: 73px; */
            left: 62px;
            font-weight: 600;
            // padding-top: 3px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .route-direction {
    .directions {
      .direction-from input[type="text"],
      .direction-to input[type="text"],
      .locate input[type="text"] {
        width: 28vh;
      }
    }
  }
}
