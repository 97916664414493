.event-box {
  display: flex;
  position: relative;
  top: 8px;
  .event-btn {
    padding: 8px;
    button {
      padding: 0px !important;
      box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.2) 0px 1px 3px 1px;
      display: flex;
      justify-content: center;
      border: none;
      width: 10vw;
      align-items: center;
      height: 4vh;
      span {
        color: white;
        font-size: 12px !important;
        font-weight: bolder !important;
      }
    }
    button:focus {
      border: 0.1rem solid rgb(227, 117, 71) !important ;
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .modal-title {
          font-size: 1.3rem;
          font-weight: bold;
        }
      }
      .modal-body {
        img {
          height: 150px;
          width: 100%;
        }
        p {
          font-size: 0.9rem;
          text-align: justify;
          font-weight: bold;
        }
        .location-date {
          img {
            height: 20px;
            width: 20px;
          }
          span {
            font-size: 0.8rem;
            font-weight: bold;
          }
        }
        .btn-section {
          margin-top: 7px;
          a,
          button {
            background-color: rgb(227, 117, 71);
            margin: 2px;
            border: none;
            font-weight: bold;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .event-box {
    .event-btn {
      button {
        width: 25vw;
      }
    }
  }
}
