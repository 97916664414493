* {
  font-family: "Montserrat", sans-serif;
}

.view {
  button {
    border: none;
    img {
      height: 26px;
      width: 26px;
    }
  }
}
.reverse-geocode {
  position: absolute;
  z-index: 1;
  .point-info {
    width: 22%;
    transform: translate(-50%, -9%);
    margin: 0px auto;
    background-color: #ffff;
    border-radius: 5px;
    position: fixed;
    bottom: 7px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    // text-align: center;
    left: 50%;
    // border-width: 0px, 0px, 0px, 0px;
    // border-style: solid;
    // border-color: #91a4b75c;
    padding: 4px;
    .point-info-inner {
      padding: 4px;
      hr {
        margin: 0;
      }

      .point-title {
        // display: flex;
        // justify-content: space-between;
        img {
          // padding: 2px;
          height: 17px;
          width: 17px;
        }
        span {
          font-size: 13px;
          font-weight: bolder;
          line-height: 20px;
          color: #0d1829;
        }
      }
      small {
        font-weight: 700;
        color: #61758a;
        padding-bottom: 0;
        font-size: 0.9em;
      }
      p {
        font-size: 0.9em;
        font-weight: 600;
        color: #1e74f3;
        line-height: 20px;
        // margin-top: 7px;
        margin-bottom: 5px;
      }
    }

    .add-content {
      padding-top: 7px;
      img {
        height: 25px;
        width: 25px;
      }
      span {
        font-size: 12px;
        font-weight: 700;
      }
    }
    .point-direction {
      display: flex;

      .direction-btn,
      .share-btn {
        padding-block-end: 0;
        padding-block-start: 0;
        background-color: #e37547;
        border: none;
        border-radius: 8px;
        // padding: 8px;
        display: flex;
        margin: 6px;
        justify-content: center;
        align-items: center;
        img {
          height: 20px;
          width: 20px;
        }
        span {
          font-size: 13px;
          font-weight: 600;
          margin-left: 6px;
          color: #ffff;
          word-spacing: 3%;
        }
      }
      .share-btn {
        background-color: #fee2d6;
        border: none;
        border-radius: 10px;
        padding: 8px;
      }
    }
  }
  .pointinfo-select {
    width: 19%;
    transform: translate(12%, -9%);
    margin: 0px auto;
    background-color: #ffff;
    border-radius: 5px;
    position: fixed;
    bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    // text-align: center;
    left: 50%;
    // border-width: 0px, 0px, 0px, 0px;
    // border-style: solid;
    // border-color: #91a4b75c;
    padding: 4px;
    .point-info-inner {
      padding: 4px;
      hr {
        margin: 0;
      }

      .point-title {
        // display: flex;
        // justify-content: space-between;
        img {
          padding: 2px;
          height: 15px;
          width: 15px;
        }
        span {
          font-size: 13px;
          font-weight: bolder;
          line-height: 20px;
          color: #0d1829;
        }
        small {
          font-weight: 700;
          color: #61758a;
          padding-bottom: 0;
          font-size: 0.9em;
        }
      }
      p {
        font-size: 0.9em;
        font-weight: 600;
        color: #1e74f3;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }

    .add-content {
      padding-top: 7px;
      img {
        height: 25px;
        width: 25px;
      }
      span {
        font-size: 12px;
        font-weight: 700;
      }
    }
    .point-direction {
      display: flex;

      .direction-btn,
      .share-btn {
        background-color: #e37547;
        border: none;
        border-radius: 8px;
        // padding: 8px;
        display: flex;
        margin: 6px;
        justify-content: center;
        align-items: center;
        img {
          height: 20px;
          width: 20px;
        }
        span {
          font-size: 13px;
          font-weight: 600;
          margin-left: 6px;
          color: #ffff;
          word-spacing: 3%;
        }
      }
      .share-btn {
        background-color: #fee2d6;
        border: none;
        border-radius: 10px;
        padding: 8px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .reverse-geocode {
    .point-info {
      width: 80%;
      // transform: translate(-50%, 157%);
    }
  }
}

@media screen and (max-width: 700px) {
  .reverse-geocode {
    .point-info {
      width: 68%;
      bottom: 155px;
      left: 38%;
      // transform: translate(-50%, 157%);
    }
  }
}
