.maplibregl-ctrl-bottom-right {
  bottom: 48px;
  .maplibregl-ctrl-group {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .maplibregl-ctrl-zoom-in,
    .maplibregl-ctrl-zoom-out,
    .maplibregl-ctrl-compass {
      .maplibregl-ctrl-icon {
        filter: invert(62%) sepia(9%) saturate(790%) hue-rotate(192deg)
          brightness(94%) contrast(90%);
      }
    }
    button {
      width: 34px;
      height: 34px;
    }
    .maplibregl-ctrl-fullscreen,
    .maplibregl-ctrl-geolocate {
      .maplibregl-ctrl-icon {
        filter: invert(55%) sepia(13%) saturate(2649%) hue-rotate(331deg)
          brightness(98%) contrast(81%);
      }
    }
  }
}

/* Media Query for Mobile Devices */
// @media (max-width: 480px) {
//   .maplibregl-ctrl-bottom-right {
//     bottom: 0;
//   }
// }

/* Media Query for low resolution  Tablets, Ipads */
// @media (min-width: 481px) and (max-width: 767px) {
//   .maplibregl-ctrl-bottom-right {
//     bottom: 0;
//   }
// }

/* Media Query for Tablets Ipads portrait mode */
// @media (min-width: 768px) and (max-width: 1024px) {
//   .maplibregl-ctrl-bottom-right {
//     bottom: 0;
//   }
// }
