.cluster-box,
.subcluster-box {
  position: relative;
  z-index: 1;
  top: -40px;
  // left: -35px;
  padding: 0px 25px;
  button {
    background-color: rgb(227, 117, 71) !important;
    box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.2) 0px 1px 3px 1px;
    border: none;

    span {
      color: white;
      font-size: 12px !important;
      font-weight: bolder !important;
    }
  }
}

.cluster-box {
  button {
    width: 12vw;
    border-radius: 30px;
  }
  button:hover {
    transition: background-color 0.3s ease !important ;
    background-color: #812c19 !important;

    span:hover {
      font-weight: bold !important;
      transition: background-color 0.3s ease !important ;
    }
  }
  .selected {
    background-color: #812c19 !important;
  }
  button:focus {
    background-color: #812c19 !important;
  }
}

.subcluster-box {
  button:hover {
    transition: background-color 0.3s ease !important ;
    background-color: #9a5647 !important;

    span:hover {
      font-weight: bold !important;
      transition: background-color 0.3s ease !important ;
    }
  }
  button:focus {
    background-color: #9a5647 !important;
  }
}
.cluster-box {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  white-space: nowrap;
}

.cluster-box::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.cluster-item {
  flex: 0 0 auto;
  padding: 8px;
}

.scroll-left,
.scroll-right {
  position: absolute;
  top: -26%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #e9916c;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: hidden;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.2) 0px 1px 3px 1px;
  // border: 1px solid #4e5a69;
  border-radius: 36%;
}
.scroll-left:hover,
.scroll-right:hover {
  background-color: #e37547 !important;
}

.scroll-left {
  left: 0;
}

.scroll-right {
  right: 14px;
}

.cluster-box-container {
  position: relative;
}

.cluster-box-container:hover .scroll-left {
  visibility: visible;
  // background-color: #daad9a;
}

.cluster-box-container:hover .scroll-right {
  visibility: visible;
}

@media screen and (max-width: 700px) {
  .cluster-box {
    top: -12px;
    button {
      width: 25vw;
    }
  }
}
