.heritage-box {
  display: flex;
  position: relative;
  top: 6.8px;
  button {
    width: 10vw;
    border-radius: 20px;
    margin-left: 10px;
  }
  //   position: relative;
  //   z-index: 1;
  //   top: -40px;
  //   // left: -35px;
  //   padding: 0px 25px;
  //   button {
  //     background-color: rgb(227, 117, 71) !important;
  //     box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px,
  //       rgba(60, 64, 67, 0.2) 0px 1px 3px 1px;
  //     border: none;

  //     span {
  //       color: white;
  //       font-size: 12px !important;
  //       font-weight: bolder !important;
  //     }
  //   }
}

@media screen and (max-width: 700px) {
  .heritage-box {
    button {
      width: 37vw;
    }
  }
}
