.copyright {
  position: absolute;
  z-index: 1;
  // display: flex;
  bottom: 5px;
  right: 44px;

  .view {
    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    // rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    // position: absolute;
    // z-index: 1;
    // right: -33px;
    // bottom: 198px;
    // background-color: white;
    // outline: none;
    // border-radius: 50%;
    // height: 34px;
    // width: 34px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-bottom: 10px;
    position: absolute;
    z-index: 1;
    right: -40px;
    bottom: 190px;
    margin-bottom: 10px;

    img {
      height: 50px;
      width: 50px;
    }
  }

  span {
    background-color: white;
    padding: 5px;
    font-size: 11px;

    font-weight: 600;
    position: relative;
    bottom: 12px;
    left: 17px;
    border-radius: 26px;
    // max-width: 70%;
    margin-bottom: 0.4em;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // left: 11px;
  }
  .info {
    background-color: white;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    right: -35px;
    // left: 234px;
    bottom: 7px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    // box-shadow: rgba(99, 99, 99, 3.2) 0px 2px 8px 0px;
    img {
      height: 20px;
      width: 20px;
      position: absolute;
      z-index: 1;
    }
  }
}
.bg-change {
  background-color: orange;
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .copyright {
    bottom: 0;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .copyright {
    bottom: 0;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .copyright {
    bottom: 0;
  }
}
