.user-update {
  margin: auto;

  .user-update-content {
    padding: 32px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .content-header {
    text-align: center;
    h4 {
      font-weight: 700;
      font-size: 22px;
    }
  }
  form {
    // input[type="date"],
    // input[type="text"],
    // input[type="email"] {
    //   padding: 15px;
    //   border: none;
    //   display: inline;

    //   //placeholder color:#A8A8A8
    // }
    label {
      font-size: 13px;
      font-weight: 700;
    }
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
    }
    input:focus-visible {
      outline: none;
    }
    button {
      background-color: #e37547;
      border: none;
      // border-radius: 10px;
      padding: 8px;
      // display: flex;
      margin: 4px;
      width: -webkit-fill-available;

      span {
        font-size: 13px;
        font-weight: 600;
        padding: 3px;
        color: #ffff;
        word-spacing: 3%;
      }
    }
  }
}
